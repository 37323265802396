<template>
  <v-container>
    <page-title title="配送資訊" />
    <div class="mb-5">
      <h3 class="mb-4 mt-3 heading-h3">一、運送說明</h3>
      <p>
        請設定基本運費規則，作為合作店家訂購時之參考，實際運費保留供應商調整之權。<br />
        （若無提供特定配送方式/地區/免運優惠，則該欄位留白）
      </p>
    </div>
    <v-form>
      <div class="mb-10">
        <h4 class="mb-8 text-center">本島/離島各溫層運費</h4>
        <v-row
          v-for="(row, rowIndex) in ['main_island', 'outlying_islands']"
          :key="rowIndex"
          class="row-wrapper mb-6 mx-0"
        >
          <v-col cols="3">
            <h4 class="pt-2">
              {{
                {
                  main_island: "本島",
                  outlying_islands: "離島",
                }[row]
              }}
            </h4>
          </v-col>
          <v-col cols="9" class="d-flex flex-column" :style="{ gap: '20px' }">
            <div
              v-for="(col, colIndex) in temperature"
              :key="`${rowIndex}-${colIndex}`"
              cols="8"
            >
              <div class="d-flex align-center" :style="{ gap: '30px' }">
                <span>{{
                  {
                    normal: "常溫",
                    refrigeration: "冷藏",
                    freezing: "冷凍",
                  }[col]
                }}</span>
                <number-input
                  v-model="numbers[`${row}-${col}`].amount"
                  :control="false"
                  @input="() => handleInput(row, col)"
                />
                <span>元</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div>
        <h4 class="mb-8 text-center">本島/離島各溫層免運門檻</h4>
        <v-row
          v-for="(row, rowIndex) in [
            'main_island_free',
            'outlying_islands_free',
          ]"
          :key="rowIndex"
          class="row-wrapper mb-6 mx-0"
        >
          <v-col cols="3">
            <h4 class="pt-2">
              {{
                {
                  main_island_free: "本島免運",
                  outlying_islands_free: "離島免運",
                }[row]
              }}
            </h4>
          </v-col>
          <v-col cols="9" class="d-flex flex-column" :style="{ gap: '20px' }">
            <div
              v-for="(col, colIndex) in temperature"
              :key="`${rowIndex}-${colIndex}`"
              cols="8"
            >
              <div class="d-flex align-center" :style="{ gap: '30px' }">
                <span>{{
                  {
                    normal: "常溫",
                    refrigeration: "冷藏",
                    freezing: "冷凍",
                  }[col]
                }}</span>
                <number-input
                  v-model="numbers[`${row}-${col}`].amount"
                  :control="false"
                  @input="() => handleInput(row, col)"
                />
                <span>元</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "YourComponentName",
  data() {
    return {
      numbers: {}, // Add this line
      area: [
        "main_island",
        "outlying_islands",
        "main_island_free",
        "outlying_islands_free",
      ],
      temperature: ["normal", "refrigeration", "freezing"],
    };
  },
  components: {
    "number-input": () => import("@/modules/base/components/numberInput.vue"),
  },
  created() {
    for (let row of this.area) {
      for (let col of this.temperature) {
        const value = {
          id: null,
          area: row,
          temperature: col,
          amount: 0,
        };
        this.$set(this.numbers, `${row}-${col}`, value);
      }
    }
    // console.log("numbers", this.numbers);
  },
  methods: {
    async fetchData() {
      const apiData = await this.readApi();
      for (let row of this.area) {
        for (let col of this.temperature) {
          for (let item of apiData) {
            if (item.area === row && item.temperature === col) {
              this.$set(this.numbers, `${row}-${col}`, {
                id: item.id,
                area: row,
                temperature: col,
                amount: item.amount,
              });
              continue;
            }
          }
        }
      }
      // id 為 null 時，呼叫創建 this.creatApi(value) 並將回傳 id 設定
      // console.log("numbers", this.numbers);
      for (let key in this.numbers) {
        let value = this.numbers[key];
        if (value.id === null) {
          const reatData = await this.creatApi(value);
          if (reatData && reatData.id) {
            value.id = reatData.id;
          } else {
            console.error("API request failed: ", reatData);
          }
        }
      }
      // console.log("numbers-id", this.numbers);
    },
    async handleInput(row, col) {
      let value = this.numbers[`${row}-${col}`];
      if (Object.prototype.hasOwnProperty.call(value, "amount")) {
        value.amount = Number(value.amount);
      }
      try {
        if (value.id !== null) {
          this.updateApi(value, value.id);
        } else {
          const reatData = await this.creatApi(value);
          if (reatData && reatData.id) {
            value.id = reatData.id;
          } else {
            console.error("API request failed: ", reatData);
          }
        }
      } catch (err) {
        console.error("API request failed: ", err);
      }
    },
    async readApi() {
      try {
        const res = await this.$api.collection.providerApi.getFreightPrice(
          this.providerId
        );
        return res;
      } catch (err) {
        console.err(err);
        return [];
      }
    },
    async creatApi(formData) {
      try {
        const res = await this.$api.collection.providerApi.creatFreightPrice(
          this.providerId,
          formData
        );
        return res;
      } catch (err) {
        console.error("err", err);
        throw err;
      }
    },
    async updateApi(formData, id) {
      try {
        const res = await this.$api.collection.providerApi.updateFreightPrice(
          this.providerId,
          formData,
          id
        );
        return res;
      } catch (err) {
        console.err(err);
        throw err;
      }
    },
  },
  computed: {
    providerId() {
      return this.$store.getters["member/providerId"];
    },
  },
  async mounted() {
    this.$root.gaLogEvent("供應商_配送資訊頁");
    this.fetchData();
  },
};
</script>

<style scoped>
.row-wrapper {
  border: 1px solid #c1c1c1;
  padding: 10px 0;
  border-radius: 10px;
}
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  overflow: hidden;
}

.table tr:last-child td,
.table tbody tr:last-child th {
  border-bottom: none;
}
.table td,
.table th {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #c1c1c1;
}
.table th span {
  padding-right: 25px;
}
.rol-th {
  width: 85px;
}
.heading-h3 {
  font-size: 17px;
  font-weight: 500;
}
p {
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .table td,
  .table th {
    font-size: 13px;
    padding: 7px;
  }
  .rol-th {
    width: 75px;
  }
}
</style>
